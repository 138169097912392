import { CreateDEXNEBotRequest } from "src/api/bots/DEX_NE/create";
import { RequiredFormData } from "src/helpers/forms/types";
import { Mapper, entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { DEXNEChainExchangesMap } from "../Info/Exchanges/types";
import { DEXNECreateSettings } from "../shared/types";
import { DEXNEChainExchangesVersionsMap, DEXNEExchangesVersionsMap } from "./types";

export const mapChainExchangesToChainVersionExchanges: Mapper<
  DEXNEChainExchangesMap,
  DEXNEChainExchangesVersionsMap
> = (supportedChainExchanges) => {
  const chainVersionExchangesEntries = entries(supportedChainExchanges).map((entry) => {
    if (!entry) return null;

    const [chainName, exchanges] = entry;
    if (!chainName || !exchanges) return null;

    const versionExchanges = Object.values(exchanges).reduce<DEXNEExchangesVersionsMap>(
      (versionsMap, exchange) => {
        if (exchange) {
          const { dexVersion: version } = exchange;

          const currentVersionExchanges = versionsMap[version] ?? [];
          currentVersionExchanges.push(exchange);

          // eslint-disable-next-line no-param-reassign
          versionsMap[version] = currentVersionExchanges;
        }
        return versionsMap;
      },
      {}
    );

    return [chainName, versionExchanges] as const;
  });

  return Object.fromEntries(filterBoolean(chainVersionExchangesEntries));
};

export const settingsToRequestData: Mapper<
  RequiredFormData<DEXNECreateSettings>,
  CreateDEXNEBotRequest
> = (settings) => {
  const {
    base_data: { party, network, pair, base, quote, exchange },
    main_data: {
      bot_name,
      stable,
      stable_pool,
      limit_executors_num,
      volume_executors_num,
      ...mainData
    },
    volume_data: { on: volumeOn, ...volumeData },
    limit_data: { on: limitOn, data: limitStrategies, ...limitData },
  } = settings;

  return {
    party_name: party,
    pool_address: pair,
    base,
    quote,
    bot_name,
    chain_name: network,
    dex_name: exchange,
    limit_executors_num,
    volume_executors_num,
    main_settings: {
      ...mainData,
      stable,
      stable_pool,
    },
    volume_settings: {
      ...volumeData,
    },
    limit_settings: {
      ...limitData,
      settings: limitStrategies,
    },
  };
};
