import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { DEXNERoutes, DEXNEAbility } from "src/app/constants";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { Loader } from "src/components/shared/Loader";
import { AllBotsContext } from "src/context/AllBots";
import { joinMarket } from "src/helpers/botName";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useBotsVirtualizer } from "src/components/AllBots/hooks/useBotsVirtualizer";
import { useDEXNEChainsInfo } from "src/context/DEX_NE/Info";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import {
  DEXCommonBot,
  DEXCommonBotMob,
  DEXCommonBotProps,
  DEXCommonConfigProps,
} from "../shared/DEXCommon";
import { HeaderCell } from "../Header";
import { getDEXNEModeProps } from "./utils";
import * as styles from "./style";

export const DEXNE_HEADER_INFO_CELLS: HeaderCell[] = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Chain", icon: "" },
  { title: "Limit", icon: "" },
  { title: "Volume", icon: "" },
];

export const DEXNE_BOT_TEMPLATE_COLUMNS =
  "minmax(150px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) repeat(2, minmax(90px, 1fr)) 70px auto";

const MOBILE_COMPACT_QUERY = getMobileMediaQuery("900px");

export const dexNEBotsConfigProps: DEXCommonConfigProps = {
  abilities: DEXNEAbility,
  routes: DEXNERoutes,
  botType: "DEX_NE",
  botTemplateColumnsStyle: DEXNE_BOT_TEMPLATE_COLUMNS,
};

export const DEXNEBots = observer(() => {
  const { realtimeEnabled, dexNEBotsState: state } = useLateInitContext(AllBotsContext);

  const showMobile = useMediaQuery(getMobileMediaQuery("1100px"));
  useDEXNEChainsInfo();

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobile,
    size: state.bots.length,
  });

  const getBotInfoContainer = useCallback(() => scrollParentRef.current, [scrollParentRef]);

  useEffect(() => {
    if (realtimeEnabled) state.resumeBotsFetching();
    else state.suspendBotsFetching();

    return () => {
      if (realtimeEnabled) state.suspendBotsFetching();
    };
  }, [realtimeEnabled, state]);

  const bots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = state.bots[virtualRow.index];
    const { base, baseAddr, quote, quoteAddr, limit, volume, ...botOtherProps } = bot;
    const { botUUID, name, exchange } = bot;
    const limitMode = getDEXNEModeProps("limit", limit);
    const volumeMode = getDEXNEModeProps("volume", volume);

    const statsLink = getBotLink({
      botType: "DEX_NE",
      party: bot.party,
      uuid: bot.botUUID,
      botTab: DEXNERoutes.Stats,
    });

    const market = joinMarket({ quote, base, exchange });

    const credential = { market, name, UUID: botUUID, link: statsLink };

    const botProps: DEXCommonBotProps = {
      credential,
      favorite: true,
      limitMode,
      volumeMode,
      getInfoContainer: getBotInfoContainer,
      toggleIsWatched: state.toggleIsWatched,
      ...botOtherProps,
      ...dexNEBotsConfigProps,
      ...getVirtualRowStyles(virtualRow),
    };

    return showMobile ? (
      <DEXCommonBotMob key={botUUID} compactQuery={MOBILE_COMPACT_QUERY} {...botProps} />
    ) : (
      <DEXCommonBot key={botUUID} {...botProps} />
    );
  });

  return (
    <Container>
      {!showMobile ? (
        <styles.DEXNEHeaderMain
          favorite
          toggleWatchList={state.toggleWatchList}
          watchListEnabled={state.watchListEnabled}
          cells={DEXNE_HEADER_INFO_CELLS}
          $botTemplateColumnsStyle={DEXNE_BOT_TEMPLATE_COLUMNS}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{bots}</ContentScrollWrapper>
      </Content>
      <Loader show={state.loading && state.firstLoad} />
    </Container>
  );
});
