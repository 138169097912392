import { DEXCommonExchangeVersion } from "src/modules/bots";
import { DEXCommonBotsColorStatus } from "src/components/AllBots/Bots/shared/DEXCommon";
import {
  IDEXNEModeInfo,
  IDEXNEBot,
  IListDEXNEBot,
  DEXNEBotStatuses,
  DEXNEBotModeStatuses,
} from "./types";

const INITIAL_MODE_INFO: IDEXNEModeInfo = {
  status: DEXNEBotModeStatuses.Stopped,
  message: "",
  nextTrade: 0,
  lastTrade: 0,
};

export const INITIAL_DEX_NE_BOT: IDEXNEBot = {
  base: "",
  exchange: "",
  link: "",
  party: "",
  quote: "",
  botUUID: "",
  name: "",
  baseAddr: "",
  quoteAddr: "",
  pairAddr: "",
  chainName: "",
  isStopped: false,
  status: DEXNEBotStatuses.Stopped,
  dexVersion: DEXCommonExchangeVersion.V2,

  limit: INITIAL_MODE_INFO,
  volume: INITIAL_MODE_INFO,
};

export const INITIAL_LIST_DEX_NE_BOT: IListDEXNEBot = {
  ...INITIAL_DEX_NE_BOT,
  colorStatus: DEXCommonBotsColorStatus.Gray,
  pair: "",
  isWatched: false,
  chainMeta: null,
};
