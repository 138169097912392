import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { AccountManagerStore, IUseExchangeAccounts } from "src/state/shared/AccountManager";
import { makeLoggable } from "src/helpers/logger";
import { OrderBooksPanelStore } from "./orderbooks";
import { ArbitrageTerminalModules, IArbitrageTerminalUpdater } from "./types";
import { UpdaterStore } from "./shared/UpdaterStore";
import { IUseMarketList, MarketsProvider } from "./providers/MarketsProvider";
import { PlacedOrdersStore } from "./placedOrders";

export class ArbitrageStore
  implements IDisposable, IUseExchangeAccounts, IArbitrageTerminalUpdater, IUseMarketList
{
  private _party: string;

  private _accountsState: AccountManagerStore;

  private _orderBooksState: OrderBooksPanelStore;

  private _placedOrdersState: PlacedOrdersStore;

  private _updaterState = new UpdaterStore<ArbitrageTerminalModules>();

  private _marketsState: MarketsProvider;

  constructor(party: string) {
    makeAutoObservable(this);
    this._party = party;

    this._accountsState = new AccountManagerStore(this);

    this._orderBooksState = new OrderBooksPanelStore(this);

    this._marketsState = new MarketsProvider(this);

    this._placedOrdersState = new PlacedOrdersStore(this);

    makeLoggable<any>(this, { exchangeAccountsMap: true });
  }

  get party() {
    return this._party;
  }

  get orderBooksState() {
    return this._orderBooksState;
  }

  get placedOrdersState() {
    return this._placedOrdersState;
  }

  get exchangeAccountsMap() {
    return this._accountsState.accounts;
  }

  get accountsMap() {
    return this._accountsState.accountsMap;
  }

  get accountsState() {
    return this._accountsState;
  }

  get updateFetchData() {
    return this._updaterState.updateFetchData;
  }

  get addUpdater() {
    return this._updaterState.addUpdater;
  }

  get marketOptions() {
    return this._marketsState.marketOptions;
  }

  get marketInfoMap() {
    return this._marketsState.marketInfoMap;
  }

  get selectMarkets() {
    return this._marketsState.selectMarkets;
  }

  get selectorProps() {
    return this._marketsState.selectorProps;
  }

  get selectProps() {
    return this._marketsState.selectProps;
  }

  loadData = async () => {
    await Promise.all([
      this._accountsState.loadAccounts(),
      this._orderBooksState.loadData(),
      this._marketsState.loadData(),
    ]);

    this._marketsState.getMarketsFromLocalStorage();
  };

  destroy = () => {};
}
