import { makeAutoObservable, runInAction } from "mobx";
import { getCEXBots, getRecentParties, getWatchList } from "src/api/bots/CEX/bots";
import { addToWatchList, removeFromWatchList } from "src/api/shared/watchList";
import { getParties } from "src/api/userManager/partiesAPI";
import { getPrimitiveSelectorList, getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { Comparators } from "src/helpers/sorting";
import { CEXBotStatus, ICEXBot, RequestRecentParty } from "src/modules/bots";
import {
  BotsListSelectorValue,
  IBotsFilter,
  IBotsSelectors,
  IRecentPartiesFilter,
  IRecentPartiesProvider,
} from "src/state/shared/types/botListsTypes";
import RecentPartiesFilterStore from "./RecentPartiesFilterStore";
import RecentlyAddedFilterStore from "./RecentlyAddedFilterStore";

const BOTS_FETCHING_INTERVAL = 5000;

interface ListCEXBot extends ICEXBot {
  market: string;
  pair: string;
  isWatched: boolean;
}

export const hasChainSelector = (
  botSelectors: IBotsSelectors<boolean>
): botSelectors is IBotsSelectors<true> => botSelectors.chainsEnabled;

export class CEXBotsStore implements IRecentPartiesProvider, IBotsFilter, IBotsSelectors {
  private _bots: ListCEXBot[] = [];

  private _selectedBase: string[] = [];

  private _selectedExchanges: string[] = [];

  private _selectedParties: string[] = [];

  private _allBase: BotsListSelectorValue[] = [];

  private _allExchanges: BotsListSelectorValue[] = [];

  watchListEnabled = localStorage.getItem("watchListEnabled") === "true";

  private _watchList: string[] = [];

  currentIndex = 2;

  switchHeader = ["Price", "Volume", "Spread"];

  private _intervalHandler?: ReturnType<typeof setInterval>;

  statusFilter = {
    red: false,
    yellow: false,
    blue: false,
  };

  private _recentlyAddedFilter: RecentlyAddedFilterStore;

  private _recentPartiesFilter: IRecentPartiesFilter;

  firstLoad = true;

  showLoader = false;

  private _party = "";

  private _allParties: string[] = [];

  constructor(party?: string) {
    this._recentlyAddedFilter = new RecentlyAddedFilterStore();
    this._recentPartiesFilter = new RecentPartiesFilterStore();

    if (party) this._setParty(party);

    makeAutoObservable(this);
  }

  chainsEnabled = false as const;

  dexVersionsEnabled = false as const;

  setSelectedBases = (values: readonly BotsListSelectorValue[]) => {
    this._selectedBase = values ? values.map(({ value }) => value) : [];
  };

  setSelectedExchanges = (values: readonly BotsListSelectorValue[]) => {
    this._selectedExchanges = values ? values.map(({ value }) => value) : [];
  };

  setSelectedParties = (values: readonly BotsListSelectorValue[]) => {
    this._selectedParties = values ? values.map(({ value }) => value) : [];
  };

  get bots(): ListCEXBot[] {
    return this._bots.filter(
      (element) =>
        this._watchListFilter(element) &&
        this._statusFilter(element) &&
        this._baseFilter(element) &&
        this._exchangesFilter(element) &&
        this._partiesFilter(element) &&
        this._recentlyAddedFilter.switchableFilter(element) &&
        this._recentPartiesFilter.filter(element)
    );
  }

  set bots(bots: ICEXBot[]) {
    this._bots = bots.map((bot) => ({
      ...bot,
      market: `${bot.quote}_${bot.base}_${bot.exchange}`,
      pair: `${bot.quote}_${bot.base}`,
      isWatched: this._watchList.includes(bot.bot_uuid),
    }));
  }

  get totalBotsCount(): number {
    return this._bots.filter(
      (bot) => bot.status !== CEXBotStatus.Stopped && bot.status !== CEXBotStatus.Initial
    ).length;
  }

  get allBase() {
    return this._sortSelectorValue(this._allBase);
  }

  set allBase(data) {
    this._allBase = data;
  }

  get allExchanges() {
    return this._sortSelectorValue(this._allExchanges);
  }

  set allExchanges(data) {
    this._allExchanges = data;
  }

  get allParties() {
    const partiesOptions = getPrimitiveSelectorList(this._allParties);

    return this._sortSelectorValue(partiesOptions);
  }

  setAllExchanges = (data: any) => {
    this._allExchanges = data;
  };

  private _sortSelectorValue = (values: BotsListSelectorValue[]) =>
    values.slice().sort((that, other) => Comparators.String(that.value, other.value));

  get selectedBase() {
    return getSelectorList(this._selectedBase);
  }

  get selectedExchanges() {
    return getSelectorList(this._selectedExchanges);
  }

  get selectedParties() {
    return getSelectorList(this._selectedParties);
  }

  get watchList() {
    return this._watchList;
  }

  set watchList(data) {
    this._watchList = data;
  }

  get redStatus() {
    return this.statusFilter.red;
  }

  private get _redStatuses() {
    return this._bots.filter((el) => el.status === CEXBotStatus.Alert).length;
  }

  get redCount(): number {
    return this._redStatuses;
  }

  get yellowStatus() {
    return this.statusFilter.yellow;
  }

  private get _yellowStatuses() {
    return this._bots.filter((el) => el.status === CEXBotStatus.Warning).length;
  }

  get yellowCount(): number {
    return this._yellowStatuses;
  }

  get blueStatus() {
    return this.statusFilter.blue;
  }

  private get _blueStatuses() {
    return this._bots.filter((el) => el.status === CEXBotStatus.Paused).length;
  }

  get blueCount(): number {
    return this._blueStatuses;
  }

  get recentlyAddedEnabled() {
    return this._recentlyAddedFilter.isEnabled;
  }

  get recentlyAddedCount() {
    return this._bots.filter(this._recentlyAddedFilter.filter).length;
  }

  get recentParties() {
    return this._recentPartiesFilter.recentParties;
  }

  get isPartyMode() {
    return Boolean(this._party);
  }

  private _setParty = (party: string) => {
    this._party = party;
  };

  private _setAllParties = (parties: string[]) => {
    this._allParties = parties;
  };

  togglePartySelection = (name: string) => this._recentPartiesFilter.togglePartySelection(name);

  private _fetchAllBots = async () => {
    this._setShowLoader(true);

    try {
      // Important! Set watchList firstly.
      await this._getWatchList();

      const { isError, data } = await getCEXBots(this._party);

      if (!this.isPartyMode) {
        await this._getPartyList();

        await this._getRecentParties();
      }

      if (!isError) {
        const { arrayBots, allClients, allExchanges } = data;

        runInAction(() => {
          this.bots = arrayBots?.length ? arrayBots : [];
          this.allBase = allClients?.length ? allClients : [];
          this.allExchanges = allExchanges?.length ? allExchanges : [];
        });
      }
    } catch (error) {
      logError(error);
    } finally {
      runInAction(() => {
        this.firstLoad = false;
      });
      this._setShowLoader(false);
    }
  };

  private _getRecentParties = async () => {
    try {
      const { isError, data } = await getRecentParties();
      if (!isError) {
        const parties = data.parties as RequestRecentParty[];
        this._recentPartiesFilter.setRecentParties(parties);
      }
    } catch (error) {
      logError(error);
    }
  };

  private _getPartyList = async () => {
    try {
      const { isError, data } = await getParties();

      if (!isError) {
        this._setAllParties(data);
      }
    } catch (error) {
      logError(error);
    }
  };

  private _getWatchList = async () => {
    try {
      const { isError, data } = await getWatchList();

      if (!isError) {
        const watchList = data;

        runInAction(() => {
          this.watchList = watchList?.length ? watchList : [];
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  resumeBotsFetching = () => {
    this._fetchAllBots();
    this._intervalHandler = setInterval(() => {
      this._fetchAllBots();
    }, BOTS_FETCHING_INTERVAL);
  };

  suspendBotsFetching = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  toggleWatchList = () => {
    this.watchListEnabled = !this.watchListEnabled;
    localStorage.setItem("watchListEnabled", String(this.watchListEnabled));
  };

  toggleSwitchColumn = () => {
    this.currentIndex =
      this.currentIndex + 1 === this.switchHeader.length ? 0 : this.currentIndex + 1;
  };

  toggleRedStatus = () => {
    this.statusFilter.red = !this.statusFilter.red;
  };

  toggleYellowStatus = () => {
    this.statusFilter.yellow = !this.statusFilter.yellow;
  };

  toggleBlueStatus = () => {
    this.statusFilter.blue = !this.statusFilter.blue;
  };

  toggleRecentlyAddedEnabled = () => {
    this._recentlyAddedFilter.toggleEnabled();
  };

  removeBaseSelected = (item: string) => {
    this._selectedBase = this._selectedBase.filter((value) => value !== item);
  };

  removeExchSelected = (item: string) => {
    this._selectedExchanges = this._selectedExchanges.filter((value) => value !== item);
  };

  removePartiesSelected = (item: string) => {
    this._selectedParties = this._selectedParties.filter((value) => value !== item);
  };

  toggleIsWatched = async (bot_uuid: string) => {
    this._setShowLoader(true);
    const botIndex = this._findBotIndex(bot_uuid);
    const { isWatched } = this._bots[botIndex] as any;
    this._bots[botIndex].isWatched = !isWatched;
    try {
      if (isWatched) {
        await removeFromWatchList(bot_uuid);
        runInAction(() => {
          this._watchList = this._watchList.filter((item) => item === bot_uuid);
        });
      } else {
        await addToWatchList(bot_uuid);
        runInAction(() => {
          this._watchList.push(bot_uuid);
        });
      }
    } catch {
      runInAction(() => {
        this._bots[botIndex].isWatched = isWatched;
      });
    } finally {
      this._setShowLoader(false);
    }
  };

  private _findBotIndex = (bot_uuid: string) =>
    this._bots.findIndex((item) => item.bot_uuid === bot_uuid);

  private _baseFilter = ({ base }: ListCEXBot) =>
    this._selectedBase.length === 0 || this._selectedBase.includes(base);

  private _exchangesFilter = ({ exchange }: ListCEXBot) =>
    this._selectedExchanges.length === 0 || this._selectedExchanges.includes(exchange);

  private _partiesFilter = ({ party }: ListCEXBot) =>
    this._selectedParties.length === 0 || this._selectedParties.includes(party);

  private _watchListFilter = ({ isWatched }: ListCEXBot) => !this.watchListEnabled || isWatched;

  private _statusFilter = ({ status }: ListCEXBot) =>
    (!this.statusFilter.red && !this.statusFilter.yellow && !this.statusFilter.blue) ||
    (this.statusFilter.red && status === CEXBotStatus.Alert) ||
    (this.statusFilter.yellow && status === CEXBotStatus.Warning) ||
    (this.statusFilter.blue && status === CEXBotStatus.Paused);

  private _setShowLoader = (bool: boolean) => {
    this.showLoader = bool;
  };
}
